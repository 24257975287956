import "./App.css";
import FormFields from "./FormFields";
import Footer from "./Footer";

function App() {
  return (
    <div id="app-outer">
      <div id="app-inner">
        <h1 id="header-text">
          Mag ik een <span className="green">Tikkie</span> sturen
          <span className="green">?</span>
        </h1>
        <p id="sub-title">
          Vraag je je wel eens af of het wel OK is om een Tikkie te sturen voor
          een paar euro? Check snel of het mag!
        </p>
        <FormFields />
      </div>
      <div id="scroll-to-anchor"></div>
      <Footer />
    </div>
  );
}

export default App;
