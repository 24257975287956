import React, { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import JaNeeTikkie from "./JaNeeTikkie";
import PopUpFields from "./PopUpFields";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

function FormFields() {
  const [amount, setAmount] = useState();
  const [people, setPeople] = useState();
  const [income, setIncome] = useState();
  const [relation, setRelation] = useState(3);
  const [result, setResult] = useState();
  const [relationDescription, setRelationDescription] = useState(
    "Vrienden / collega's"
  );

  useEffect(() => {
    switch (relation) {
      case "1":
        setRelationDescription("Onbekenden");
        break;
      case "2":
        setRelationDescription("Vage kennissen");
        break;
      case "3":
        setRelationDescription("Vrienden / collega's");
        break;
      case "4":
        setRelationDescription("Goede vrienden / familie");
        break;
      case "5":
        setRelationDescription("Beste vrienden / ouders");
        break;
      default:
    }
  }, [relation, setRelationDescription]);

  const scrollToBottom = () => {
    setTimeout(function () {
      document.querySelector("#scroll-to-anchor").scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "center",
      });
    }, 200);
  };

  const handleCalc = (e) => {
    e.preventDefault();
    let ppCorrect = Math.sqrt(amount / people / 5);

    let incomeCorrect = 1;
    if (income > 700) {
      incomeCorrect = Math.pow(1000 / (income - 500), 1 / 1.1);
    } else {
      incomeCorrect = Math.pow(1000 / 200, 1 / 1.1);
    }
    let relationCorrect = Math.pow(2, (3 - relation) / 2);
    let numeralResult = 1;
    if (people === "1") {
      numeralResult =
        ((amount * 2) / 10) * ppCorrect * incomeCorrect * relationCorrect;
    } else {
      numeralResult =
        (amount / 10) * ppCorrect * incomeCorrect * relationCorrect;
    }

    numeralResult >= 1 ? setResult(1) : setResult(0);
    scrollToBottom();
  };

  const openPopup = (type) => {
    document
      .querySelectorAll(".popup")
      .forEach((popup) => popup.classList.remove("active"));
    document.querySelector(`#${type}.popup`).classList.add("active");
  };

  useEffect(() => {
    setResult(-1);
  }, [amount, people, income, relation]);

  return (
    <form>
      <div className="input-field">
        <p>Bedrag</p>
        <FontAwesomeIcon
          icon={faCircleQuestion}
          onClick={() => openPopup("bedrag")}
        />
        <CurrencyInput
          id="input-example"
          name="amount"
          value={amount}
          placeholder="bijv. €10,00"
          decimalsLimit={2}
          allowNegativeValue={false}
          onValueChange={(value) => setAmount(value)}
          prefix="€"
        />
      </div>
      <div className="input-field">
        <p>Aantal personen</p>
        <FontAwesomeIcon
          icon={faCircleQuestion}
          onClick={() => openPopup("amount")}
        />
        <input
          type="number"
          min="1"
          value={people}
          placeholder="bijv. 5"
          onChange={(e) => setPeople(e.target.value)}
        />
      </div>
      <div className="input-field">
        <p>Jouw maandinkomen</p>
        <FontAwesomeIcon
          icon={faCircleQuestion}
          onClick={() => openPopup("income")}
        />
        <CurrencyInput
          id="input-example"
          name="amount"
          value={income}
          placeholder="bijv. €2.000,00"
          decimalsLimit={2}
          allowNegativeValue={false}
          onValueChange={(value) => setIncome(value)}
          prefix="€"
        />
      </div>
      <div className="input-field">
        <p>Relatie</p>
        <FontAwesomeIcon
          icon={faCircleQuestion}
          onClick={() => openPopup("relation")}
        />
        <input
          type="range"
          className="slider"
          min="1"
          max="5"
          value={relation}
          step="1"
          onChange={(e) => setRelation(e.target.value)}
        />
        <p id="relation-description">{relationDescription}</p>
      </div>
      <button
        onClick={handleCalc}
        id="calc-button"
        disabled={
          !amount || !people || !income || people === "0" || amount === "0"
        }
      >
        Bereken!
      </button>
      {result > -1 && (
        <div id="result-outer-div">
          {result === 1 ? (
            <JaNeeTikkie input="yes" />
          ) : (
            <JaNeeTikkie input="no" />
          )}
        </div>
      )}
      <PopUpFields />
    </form>
  );
}

export default FormFields;
