import React from "react";

function Footer() {
  const openPopup = (type) => {
    document
      .querySelectorAll(".popup")
      .forEach((popup) => popup.classList.remove("active"));
    document.querySelector(`#${type}.popup`).classList.add("active");
  };
  return (
    <>
      <div id="footer">
        <p>
          <a href="mailto:info@magikeentikkiesturen.nl">
            info@magikeentikkiesturen.nl
          </a>
        </p>

        <p class="a-styled-p" onClick={() => openPopup("privacy")}>
          Privacy statement
        </p>

        <p>Door: Martijn Keijzer</p>
      </div>
    </>
  );
}

export default Footer;
