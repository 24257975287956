import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

function PopUpFields() {
  const closePopUp = () => {
    document
      .querySelectorAll(".popup")
      .forEach((popup) => popup.classList.remove("active"));
  };
  return (
    <>
      <div className="popup" id="bedrag">
        <FontAwesomeIcon icon={faCircleXmark} onClick={closePopUp} />
        <h2>Bedrag</h2>
        <p>
          Vul hier het totale bedrag in waarvoor je een Tikkie wil sturen. Stel
          je haalt met twee vrienden een frietje en alle frietjes zijn 5 euro,
          dan zet je hier 15 euro.
        </p>
      </div>
      <div className="popup" id="amount">
        <FontAwesomeIcon icon={faCircleXmark} onClick={closePopUp} />
        <h2>Aantal personen</h2>
        <p>
          Vul hier het aantal personen in inclusief jijzelf. Als je dus voor jou
          en voor een vriend iets hebt betaald dan is het aantal 2. Als je voor
          een iemand iets hebt betaald en niet ook voor jezelf dan zet je hier
          1.
        </p>
      </div>
      <div className="popup" id="income">
        <FontAwesomeIcon icon={faCircleXmark} onClick={closePopUp} />
        <h2>Jouw maandinkomen</h2>
        <p>
          Vul hier in hoeveel jij per maand te besteden hebt. Als je werkt is
          dit je netto maandinkomen. Als je studeert is dit hoeveel je in totaal
          van DUO en je ouders krijgt plus eventueel nog inkomen uit werk.
        </p>
      </div>
      <div className="popup" id="relation">
        <FontAwesomeIcon icon={faCircleXmark} onClick={closePopUp} />
        <h2>Relatie</h2>
        <p>
          Vul hier jouw relatie tot de personen waar je een Tikkie aan wilt
          sturen in. Niet alle soorten relatie staan erbij, dus bedenk zelf wat
          het beste aansluit. Je partner zou je helemaal rechts kunnen doen.
        </p>
      </div>
      <div className="popup" id="privacy">
        <FontAwesomeIcon icon={faCircleXmark} onClick={closePopUp} />
        <h2>Privacy statement</h2>
        <p>
          De ingevoerde gegevens worden nergens opgeslagen en er worden geen
          Cookies gebruikt om je te tracken. Gefeliciteerd, je hebt zonet voor
          het eerst in je leven het hele privacy statement van een website
          gelezen!
        </p>
      </div>
    </>
  );
}

export default PopUpFields;
