import React from "react";
import yes_gif_1 from "./gifs/yes_gif_2.gif";
import no_gif_1 from "./gifs/no_gif_2.gif";

function JaNeeTikkie({ input }) {
  const resBool = input === "yes";
  return (
    <div id="result-div">
      {resBool ? (
        <>
          <img src={yes_gif_1} alt="Ja!" />
          <h2>Ja het mag!</h2>
          <p>Nice! Laat die euro's maar komen!</p>
        </>
      ) : (
        <>
          <img src={no_gif_1} alt="Nee!" />
          <h2>Nee!</h2>
          <p>Helaas! Daar mag je geen Tikkie voor sturen.</p>
        </>
      )}
    </div>
  );
}

export default JaNeeTikkie;
